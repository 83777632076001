<template>
  <el-card shadow="never">
    <el-row style="margin-bottom: 22px">
      <el-input v-model="queryInfo.lendOrderCode" class="input" style="width: 250px" placeholder="借出单号" clearable></el-input>
      <el-input v-model="queryInfo.materialCode" class="input" placeholder="备件编码" clearable></el-input>
      <el-date-picker value-format="yyyy-MM-dd" v-model="queryInfo.ruleDate" type="date" placeholder="加急日期" class="input"></el-date-picker>
      <el-select v-model="queryInfo.finish" placeholder="是否完成" clearable class="input">
        <el-option label="是" :value="1"></el-option>
        <el-option label="否" :value="0"></el-option>
      </el-select>
      <el-button type="primary" @click="search" :loading="loading">查询</el-button>
      <el-button type="primary" @click="exportData" :loading="exportLoading">导出明细</el-button>
      <el-button type="primary" @click="dialogVisible = true" style="margin-left: 50px">添加</el-button>
      <el-button type="primary" @click="importDialogVisible = true">批量添加</el-button>

    </el-row>
    <div style="color: orange;padding-bottom:10px;font-size: 12px">
      提示:包装完成即表示线体看板加急完成，归还回绑完成表示加急完成。
    </div>
    <el-table :data="urgentRuleList" border style="width: 100%" stripe>
      <el-table-column label="#" type="index" align="center" width="45"></el-table-column>
      <el-table-column align="center" prop="lendOrderCode" label="借出单号" width="200"></el-table-column>
      <el-table-column align="center" prop="materialCode" label="备件编码"></el-table-column>
      <el-table-column align="center" prop="amount" label="数量"></el-table-column>
      <el-table-column align="center" prop="packFinishCnt" label="包装完成数量"></el-table-column>
      <el-table-column align="center" prop="finishCnt" label="归还完成数量"></el-table-column>
      <el-table-column align="center" prop="finish" label="是否完成" :formatter="isFormatter"></el-table-column>
      <el-table-column align="center" prop="ruleDate" label="加急日期"></el-table-column>
      <el-table-column align="center" prop="createUserName" label="创建人"></el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-button @click="deleteRule(scope.row)" type="danger" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-box">
      <el-pagination
          :total="total"
          layout="total,prev, pager, next"
          background
          :page-size="pageSize"
          @current-change="currentChange"
      ></el-pagination>
    </div>
    <el-dialog title="添加今日加急备件" :visible.sync="dialogVisible" :close-on-click-modal="false" width="500px" :before-close="handleClose">
      <el-form v-model="formData" label-width="150px">
        <el-form-item label="备件编码">
          <el-input v-model="formData.materialCode" placeholder="请输入备件编码" style="width: 200px" clearable></el-input>
        </el-form-item>
        <el-form-item label="数量">
          <el-input v-model="formData.amount" placeholder="请输入数量" type="number" max="10000" min="0" style="width: 200px" clearable></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="addRule" :loading="addLoading">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="导入今日加急备件" :visible.sync="importDialogVisible" :close-on-click-modal="false" width="800px" :before-close="importHandleClose">
      <ImportUrgentComponent/>
      <div slot="footer" class="dialog-footer">
        <el-button @click="importHandleClose">关闭</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>

<script>
import ImportUrgentComponent from "@/components/ImportUrgentComponent";
import {currentDay} from "@/utils/common";

export default {
  name: "UrgentRule",
  components: {ImportUrgentComponent},
  data() {
    return {
      queryInfo: {
        lendOrderCode: '',
        materialCode: '',
        finish: undefined,
        ruleDate: currentDay(),
        pageNo: 1
      },
      formData: {
        lendOrderCode: '',
        materialCode: ''
      },
      total: 0,
      pageSize: 20,
      urgentRuleList: [],
      dialogVisible: false,
      importDialogVisible: false,
      loading: false,
      addLoading: false,
      exportLoading: false
    }
  },
  created() {
    this.search();
  },
  methods: {
    exportData() {
      if (!this.queryInfo.ruleDate) {
        return this.$message.error('请选择加急日期');
      }
      this.exportLoading = true;
      this.$axios.post('ruleUrgent/export', this.queryInfo, {timeout: 600000}).then(response => {
        this.exportLoading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }

        if (res.data.url) {
          let form = document.createElement('form');
          form.action = res.data.url;
          form.target = '_blank';
          document.body.appendChild(form);
          form.submit();
          //window.open(res.data.url, "_blank")
        }
      })
    },
    async search() {
      this.urgentRuleList = []
      this.loading = true;
      const {data: res} = await this.$axios.post('ruleUrgent/queryRule', this.queryInfo);
      this.loading = false;
      if (res.code !== 0) {
        return this.$message.error(res.message);
      }
      this.urgentRuleList = res.data.records || [];
      this.total = res.data.total
      this.pageSize = res.data.size;
    },
    deleteRule(row) {
      this.$confirm('确定删除吗？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        this.$axios.post('ruleUrgent/deleteRule', row.id).then(response => {
          if (response.data.code !== 0) {
            return this.$message.error(response.data.message);
          }
          this.$message.success("删除成功");
          this.queryInfo.pageNo = 1;
          this.search();
        })
      })
    },
    async addRule() {
      if (!this.formData.materialCode) {
        return this.$message.error('备件编码不能为空');
      }

      if (!this.formData.amount) {
        return this.$message.error('请输入数量');
      } else if (this.formData.amount < 1) {
        return this.$message.error('数量不正确');
      }

      this.addLoading = true;
      const {data: res} = await this.$axios.post('ruleUrgent/addRule', this.formData);
      this.addLoading = false;
      if (res.code !== 0) {
        return this.$message.error(res.message);
      }
      this.$message.success("添加成功");
      this.queryInfo.pageNo = 1;
      this.search();
      this.handleClose()
      this.formData.lendOrderCode = ''
      this.formData.materialCode = ''
      this.formData.finish = undefined
    },
    handleClose() {
      this.dialogVisible = false;
    },
    importHandleClose() {
      this.search();
      this.importDialogVisible = false;
    },
    currentChange(pageNo) {
      this.queryInfo.pageNo = pageNo;
      this.search();
    },
    isFormatter(row, column, cellValue) {
      if (cellValue == true) {
        return '是';
      }
      if (cellValue == false) {
        return '否';
      }
      if (cellValue == null || cellValue == '') {
        return '';
      }
    },
  }
}
</script>

<style scoped>
.input {
  width: 180px;
  margin-right: 10px;
}
</style>